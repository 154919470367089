import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "jquery/dist/jquery.min.js";
import RootElement from "./src/components/root-element";
import { AppContext } from "./src/context/app-context";
import "./src/custom.scss";
import React from "react";
import loadable from "@loadable/component";
const Layout = loadable(() => import("./src/components/Layout/Index"));
const QR = loadable(() => import("./src/components/QR/Index"));

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }
};

export const wrapRootElement = ({ element, props, pathname }) => {
  const child = (lang) =>
    props && props.location.pathname === "/qr" ? (
      <QR locale={lang} {...props}></QR>
    ) : (
      <Layout locale={lang} {...props}>
        {element}
      </Layout>
    );
  return (
    <RootElement {...props}>
      <AppContext.Consumer>
        {({ path, handlePath, locale }) => {
          if (props) {
            handlePath(props.location.pathname);
          }
          return child(locale);
        }}
      </AppContext.Consumer>
    </RootElement>
  );
};
